<template>
  <!--begin::Head-->
  <!--end::Head-->

  <!--begin::Body-->
  <div class="kt-login__body">
    <!--begin::Signin-->
    <div class="kt-login__form">
      <div class="kt-login__title">
        <h3>{{ title }}</h3>
      </div>

      <div v-if="message" class="kt-container mt-5 w-100">
        <b-alert show variant="info" class="d-flex">
          {{ message }}
        </b-alert>
      </div>

      <!--begin::Form-->
      <b-form class="kt-form" @submit.stop.prevent="onSubmit">
        <div
          v-if="isSomethingWrong"
          role="alert"
          class="alert fade alert-danger show d-flex flex-column"
          v-html="errorMessage"
        ></div>

        <b-form-group label="" label-for="example-input-2">
          <b-form-input
            type="password"
            id="password"
            name="password"
            v-model="$v.form.password.$model"
            :state="formSate.password"
            placeholder="Password"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password should be at least 8 characters in length and should
            include at least one upper case letter, one number, and one special
            character.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="" label-for="example-input-2">
          <b-form-input
            type="password"
            id="passwordConfirm"
            name="passwordConfirm"
            v-model="$v.form.passwordConfirm.$model"
            :state="formSate.passwordConfirm"
            placeholder="Password Confirm"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Both passwords must be the same.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="kt-login__actions">
          <div class="w-100 text-center">
            <b-button
              :disabled="!!disabled"
              type="submit"
              size="sm"
              class="btn btn-primary btn-elevate kt-login__btn-primary px-1"
              style="width: 180px"
            >
              <strong>{{ submitBtnTitle }} </strong>
              <b-spinner
                v-if="isLoading"
                small
                type="grow"
                variant="light"
                class="ml-auto"
              ></b-spinner>
            </b-button>
          </div>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
  <!--end::Body-->
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { passwordReset } from "@/api/users/api-v2";
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "SetPassword",

  props: {
    title: {
      type: String,
      default: "",
      required: true
    },
    submitBtnTitle: {
      type: String,
      default: "",
      required: true
    },
    message: {
      type: String,
      default: ""
    }
  },

  mixins: [validationMixin],

  data() {
    return {
      // Remove this dummy login info
      form: {
        password: "",
        passwordConfirm: ""
      },
      disabled: true,
      formSate: {
        password: null,
        passwordConfirm: null
      },
      isSomethingWrong: false,
      errorMessage: "",
      isLoading: false
    };
  },

  validations: {
    form: {
      password: {
        required
      },
      passwordConfirm: {
        required
      }
    }
  },

  mounted() {
    this.$store.dispatch(LOGOUT);
  },

  watch: {
    form: {
      handler(val) {
        const pwdValidation = this.validator(val.password);
        const pwdCValidation = this.validator(val.passwordConfirm);

        if (0 < val.password.length) {
          this.formSate.password = pwdValidation;
        } else {
          this.formSate.password = null;
        }

        if (0 < val.passwordConfirm.length) {
          this.formSate.passwordConfirm =
            pwdCValidation && this.form.password === this.form.passwordConfirm;
        } else {
          this.formSate.passwordConfirm = null;
        }

        this.disabled = !(
          pwdValidation &&
          pwdCValidation &&
          this.form.password === this.form.passwordConfirm
        );
      },
      deep: true
    }
  },

  methods: {
    validator(password) {
      return (
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /[0-9]/.test(password) &&
        /[^\w]/.test(password) &&
        8 <= password.length
      );
    },

    async onSubmit() {
      this.$v.form.$touch();
      this.isSomethingWrong = false;
      this.errorMessage = "";
      this.isLoading = true;
      this.disabled = true;

      if (this.$v.form.$anyError) {
        this.disabled = false;
        this.isLoading = false;
        return;
      }

      const password = this.$v.form.password.$model;
      const passwordConfirm = this.$v.form.passwordConfirm.$model;

      if (!this.validator(password) || !this.validator(passwordConfirm)) {
        this.errors.push(
          "Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character."
        );
        this.disabled = false;
        this.isLoading = false;
        return;
      }

      if (password !== passwordConfirm) {
        this.errors.push("Both passwords must be the same.");
        this.disabled = false;
        this.isLoading = false;
        return;
      }

      // TODO: remove key or token it's the same
      const params = {
        ...this.$route.query,
        token: this.$route.query.key,
        password,
        passwordConfirm
      };

      try {
        await passwordReset(this.axios, params);

        this.$bvToast.toast(
          "Congratulations! Your password has been updated successfully.",
          {
            title: "Change password",
            variant: "success",
            solid: true
          }
        );

        setTimeout(() => this.$router.push({ name: "login" }), 2000);
      } catch (error) {
        console.error(error);

        const textError = error?.response?.data || "Something was wrong.";

        this.isSomethingWrong = true;
        this.errorMessage = `<p class="m-0">${textError}</p><p class="m-0">Please contact us: <a href="mailto:support@voxeus.com">support@voxeus.com</a></p>`;
        this.disabled = false;
      } finally {
        this.isLoading = false;
      }
    }
  },

  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>

<style scoped>
button:disabled {
  color: #ffffff;
}
button:hover {
  color: #e4e4e4;
}
</style>
