<template>
  <SetPassword
    :title="title"
    :submit-btn-title="submitBtnTitle"
    :message="message"
  />
</template>

<script>
import SetPassword from "@/components/password/SetPassword";

export default {
  components: { SetPassword },
  name: "PasswordReset",

  computed: {
    title() {
      return this.$t("AUTH.RESET.TITLE");
    },

    message() {
      return this.$t("AUTH.RESET.MESSAGE");
    },

    submitBtnTitle() {
      return this.$t("AUTH.RESET.BUTTON");
    }
  }
};
</script>
